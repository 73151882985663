<template>
  <a-card>
    <div class="spin-load" v-if="!showData">
      <a-spin :spinning="!showData">
      </a-spin>
    </div>
    <div class="app-container" v-if="showData">
      <a-form ref="form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="佣金发放比例" class="margin-bottom-10">
          <div class="flex align-center">
            <a-input-number v-model="form.platformGiveRatio" placeholder="请输入" :min="0" :max="100"
              :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" allow-clear
              style="width: 245px">
            </a-input-number>
            <span class="text-orange">
              （发放比例所占利润的百分比 0%~100%之间）
            </span>
          </div>
        </a-form-item>
        <a-form-item label="佣金发放模式" class="margin-bottom-10">
          <a-radio-group v-model="form.upperLevelGiveType" button-style="solid" @change="changeType">
            <a-radio-button v-for="(d, index) in option" :key="index" :value="parseInt(d.value)">{{ d.label
            }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="佣金返利" class="margin-bottom-10">
          <a-table :columns="columns" :data-source="data" bordered :pagination="false">
            <template v-for="col in levelColumn" :slot="col" slot-scope="text, record, index">
              <div :key="col">
                <a-input-number :value="text" :min="0" :max="100" @change="e => inputNumberChange(e, record.key, col)"
                  :parser="value => `${value}`.replace(/\$\s?|(,*)/g, '')" :precision="2" />
              </div>
            </template>
          </a-table>
        </a-form-item>
        <a-row class="margin-top-xl">
          <a-col :span="4"></a-col>
          <a-col :span="8">
            <a-button size="large" type="primary" :loading="loading" :disabled="loading" @click="onsubmit('ruleForm')" style="width:200px">
              确定
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-card>
</template>
<script>
import { getBrokerageConfig, saveOrEditBrokerageConfig, getGoodsBrokerageConfig, saveOrEditGoodsBrokerageConfig } from "@/api/distribution.js"
export default {
  name: "CommissionSettings",
  props: {
    goodsId: {
      type: Number
    }
  },
  data() {
    return {
      showData: false,
      option: [{
        label: '按百分比发放',
        value: 1
      }, {
        label: '按固定金额发放',
        value: 2
      }],
      loading: false,
      queryParams: {
        name: ''
      },
      form: {
        platformGiveRatio: 0,
        brokerageRatio: 100,
        upperLevelGiveType: 1
      },
      unit: '元', // % 元
      levelColumn: [],      // level1、level2、level3
      levelColumnText: [],  // 1级、2级、3级
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
          key: 'key',
          width: 80,
          align: 'center'
        },
        {
          title: '级别名称',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          width: 160
        },
        /** 动态添加 level1、level2、level3
        {
          title: '1级',
          dataIndex: 'level1',
          key: 'level1',
          align: 'center',
          scopedSlots: { customRender: 'level1' }
        },
        {
          title: '2级',
          dataIndex: 'level2',
          key: 'level2',
          align: 'center',
          scopedSlots: { customRender: 'level2' }
        },
        {
          title: '3级',
          dataIndex: 'level3',
          key: 'level3',
          align: 'center',
          scopedSlots: { customRender: 'level3' }
        }*/
      ],
      data: [],
      brokerageList: []
    };
  },
  created() {
    //获取默认分销佣金基础配置信息
    if (!this.goodsId) {
      this.getgetBrokerageConfig();
    } else {
      //获取商品佣金分配
      this.getGoodsBrokerageConfig()
    }
  },
  methods: {
    /** 切换 佣金发放模式 */
    changeType(e) {
      let type = e.target.value
      this.unit = type === 1 ? '%' :
        type === 2 ? '元' : '%'
      this.columns.map((item, index) => {
        if (index > 1) {
          this.columns[index].title = `${this.levelColumnText[index - 2]}（${this.unit}）`
        }
      })
    },
    /** input-number 赋值 */
    inputNumberChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    //获取分销佣金基础配置信息
    getgetBrokerageConfig() {
      this.showData = false;
      getBrokerageConfig().then(res => {
        if (res.success) {
          this.form = res.data
          let brokerageJson = JSON.parse(this.form.brokerageJson)
          for (let levelId in brokerageJson) {
            let leaveData = brokerageJson[levelId]
            let levelConfig = []
            for (let configLevel in leaveData.levelConfig) {
              levelConfig.push({ configLevel: configLevel, data: leaveData.levelConfig[configLevel] })
            }
            this.brokerageList.push({
              levelId: levelId,
              title: leaveData.title,
              levelConfig: levelConfig
            })
          }
          this.setTableInfo(this.brokerageList)
        }
        this.showData = true
      })
    },
    getGoodsBrokerageConfig() {
      this.showData = false;
      getGoodsBrokerageConfig(this.goodsId).then(res => {
        if (res.success) {
          this.form = res.data
          let brokerageJson = JSON.parse(this.form.brokerageJson)
          for (let levelId in brokerageJson) {
            let leaveData = brokerageJson[levelId]
            let levelConfig = []
            for (let configLevel in leaveData.levelConfig) {
              levelConfig.push({ configLevel: configLevel, data: leaveData.levelConfig[configLevel] })
            }
            this.brokerageList.push({
              levelId: levelId,
              title: leaveData.title,
              levelConfig: levelConfig
            })
          }
          this.setTableInfo()
        }
        this.showData = true
      })
    },
    setTableInfo(arr) {
      /** 构造表格数据，表头columns */
      this.brokerageList[0].levelConfig.map(item => {
        let key = item.configLevel
        this.columns.push({
          title: `${key}级（${this.unit}）`,
          dataIndex: `level${key}`,
          key: `level${key}`,
          align: 'center',
          scopedSlots: { customRender: `level${key}` }
        })
        this.levelColumn.push(`level${key}`)  //  ['level1','level2','level3']
        this.levelColumnText.push(`${key}级`) //  ['1级','2级','3级']
      })
      /**构造表格数据data,增加四个字段 序号key、level1、level2、level3 */
      this.brokerageList.map((item, index) => {
        item.key = index + 1
        item.levelConfig.map(item1 => {
          item[`level${item1.configLevel}`] = item1.data
        })
      })
      this.data = this.brokerageList
    },
    //保存数据
    onsubmit() {
      this.brokerageList = this.data
      /**将leve1、level2、level3的值 同步到 levelConfig中每一项的data上 */
      this.brokerageList.map((item) => {
        item.levelConfig.map((item2, index2) => {
          item2.data = item[`level${item2.configLevel}`] + ''
        })
      })
      let brokerageJson = Object.fromEntries(
        this.brokerageList.map(item => {
          let levelConfig = Object.fromEntries(item.levelConfig.map(item => [item.configLevel, item.data]))
          let brokerage = {
            title: item.title,
            levelConfig: levelConfig
          }
          return [item.levelId, brokerage]
        })
      )

      for (let levelId in brokerageJson) {
        //身份等级配置
        let levelConfig = brokerageJson[levelId].levelConfig
        const level = [1, 2, 3]
        for (let index in level) {
          let level1 = level[index]
          let level2 = level1
          let level3 = level1
          if (level1 == 1) {
            level2 = level2 + 1
            level3 = level3 + 2
          } else if (level1 == 2) {
            level2 = level2 - 1
            level3 = level3 + 1
          } else if (level1 == 3) {
            level2 = level2 - 2
            level3 = level3 - 1
          }
          //处理一层
          let oneContes = (levelConfig[level1] ? levelConfig[level1] : 0) * 1
          if (oneContes > 100) {
            // // console.log("等级之和："+oneContes)
            this.$message.error("佣金等级分配等级之和大于100")
            return;
          }
          //处理二层
          for (let levelId in brokerageJson) {
            let levelConfig = brokerageJson[levelId].levelConfig
            let con2 = (levelConfig[level2] ? levelConfig[level2] : 0) * 1
            oneContes = oneContes + con2
            if (oneContes > 100) {
              //// console.log("等级之和："+oneContes)
              this.$message.error("佣金等级分配等级之和大于100")
              return;
            }
            //处理三层
            for (let levelId in brokerageJson) {
              let levelConfig = brokerageJson[levelId].levelConfig
              let con3 = (levelConfig[level3] ? levelConfig[level3] : 0) * 1
              oneContes = oneContes + con3
              if (oneContes > 100) {
                //// console.log("等级之和："+oneContes)
                this.$message.error("佣金等级分配等级之和大于100")
                return;
              }
              oneContes = oneContes - con3
            }
            oneContes = oneContes - con2
          }
        }
      }
      //保存
      this.form.brokerageJson = JSON.stringify(brokerageJson)
      this.form.brokerageRatio = 100
      this.loading = true
      if (this.goodsId) {
        //保存商品
        this.form.goodsId = this.goodsId
        saveOrEditGoodsBrokerageConfig(this.form).then(res => {
          if (res.success) {
            this.$message.success("设置成功");
          }
          this.loading = false
        })
      } else {
        saveOrEditBrokerageConfig(this.form).then(res => {
          if (res.success) {
            this.$message.success("设置成功");
          }
          this.loading = false
        })
      }
    },
  }
};
</script>
<style scoped lang="less">
.tip {
  line-height: 20px !important;
  font-size: 12px;
  margin-left: 10px;
  color: orange;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.spin-load {
  text-align: center;
}

.borkerage-class {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .borkerage-item-class {
    border: 1px solid #e6eaf1;
    border-radius: 4px;
    margin: 8px;
    -webkit-transition: all .2s cubic-bezier(.55, .055, .675, .19);
    transition: all .2s cubic-bezier(.55, .055, .675, .19);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    width: 268px;
    text-align: center;

    .borkerage-item-title-class {
      border-bottom: 1px solid #e6eaf1;
      font-weight: 700;
      background: #fafafa;
    }

    .borkerage-item-level-class {
      padding: 12px 24px;
    }
  }
}
</style>